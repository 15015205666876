import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'seq0818-02-18';
  private timeout = 0;

  @HostListener('document:mousedown')
  mouse() { // detect activity and reset timeout
    window.clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      if (location.pathname !== '/home') { location.href = '/'; }
    }, 5 * 60e3); // redirect back to home after 5 minutes of inactivity
  }
}
