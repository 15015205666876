export class PrintUser {
    public role: string;
    public interest: string;
    public level: string;
    public name: string;
    public printed: boolean;

    static fromString(json: string): PrintUser {
        json = JSON.parse(json);
        return new PrintUser(json['name'], json['role'], json['interest'], json['level']);
    }

    constructor(name: string, role: string, interest: string, level: string) {
        this.name = name;
        this.role = role;
        this.interest = interest;
        this.level = level;
        this.printed = false;
    }

    toString() {
        return JSON.stringify({
            'name': this.name,
            'role': this.role,
            'interest': this.interest,
            'level': this.level
        });
    }

    get json() {
        return this.toString();
    }
}
