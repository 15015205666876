import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-volunteer-options',
  templateUrl: './volunteer-options.component.html',
  styleUrls: ['./volunteer-options.component.css']
})
export class VolunteerOptionsComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  signup() {
    this.router.navigateByUrl('/volunteer-signup');
  }

  checkin() {
    this.router.navigateByUrl('/volunteer-checkin');
  }

}
