import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Guest } from '../_models/guest.model';
import { Questions } from '../_models/questions.model';
import { MatStepper } from '@angular/material';
import { PrintUser } from '../_models/print-user.model';
import { forkJoin } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-guest-sign-up',
  templateUrl: './guest-sign-up.component.html',
  styleUrls: ['./guest-sign-up.component.css']
})
export class GuestSignUpComponent implements OnInit {
  public api = environment.apiUrl;
  guestSignupForm: FormGroup;

  forms = Questions.forms;

  guests: Array<Guest> = [];

  initialGuestAdded = false;

  isSubmitted = false;

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      }
    )
  };

  constructor(private http: HttpClient, public router: Router, private cookieService: CookieService) { }

  ngOnInit() {
    this.guestSignupForm = new FormGroup({
      firstName__c: new FormControl('', Validators.required),
      lastName__c: new FormControl('', Validators.required),
      email__c: new FormControl('', [Validators.required, CustomValidators.email], this.validateEmailNotTaken.bind(this)),
      phone__c: new FormControl('', Validators.required),
      Name: new FormControl('')
    });
  }

  validateEmailNotTaken() { // async
    return new Promise(resolve => {
      // check if user exists in sign up
      this.http.get<any>(this.api + 'path/GuestSignUpForm__c/email__c/' + this.guestSignupForm.controls['email__c'].value).subscribe(
        () => resolve({ 'emailInUse': true }), // 200 OK = user exists, return error
        error => {
          resolve(error.status === 404 ? null : { 'emailInUse': true }); // 404 Not Found = user does not exist
        });
    });
  }

  removeGuest(removeGuest: Guest) {
    this.guests = this.guests.filter(guest => guest !== removeGuest);
  }

  addGuest(guest: Guest = new Guest('', '', 'AdaptiveRidingForm__c')) {
    this.guests.push(guest);
  }

  // add initial guest after initial personal info form submit
  addInitialGuest() {
    if (this.initialGuestAdded) { // only run once
      return;
    }
    this.guests.push(new Guest(
      this.guestSignupForm.controls.firstName__c.value,
      this.guestSignupForm.controls.lastName__c.value,
      'AdaptiveRidingForm__c',
    ));
    this.initialGuestAdded = true;
  }

  submit() {
    if (!this.guestSignupForm.valid) {
      return;
    }
    this.isSubmitted = true;
    this.guestSignupForm.patchValue({ Name: `${this.guestSignupForm.value.firstName__c} ${this.guestSignupForm.value.lastName__c}` }); // set Name field so it doesn't display as the raw id in the backend
    this.http.post<any>(this.api + 'path/GuestSignUpForm__c', this.guestSignupForm.value, this.httpOptions).subscribe(result => { // create the guest account
      forkJoin(this.guests.map(guest => // create http post for each guest
        this.http.post(this.api + 'path/' + guest.formInterest, Object.assign(guest.formGroup.value, {
          'parent__c': result.id, // set parent to guest account id
          'Name': `${guest.firstName} ${guest.lastName}`,
          'email__c': this.guestSignupForm.value.email__c, // email and phone are same as guest account parent
          'phone__c': this.guestSignupForm.value.phone__c,
          'firstName__c': guest.firstName,
          'lastName__c': guest.lastName
        }), this.httpOptions)
      )).subscribe(() => { // subscribe to all the requests to send them
        // set cookie for print page
        this.cookieService.set('guests', JSON.stringify(this.guests.map(guest => new PrintUser(`${guest.firstName} ${guest.lastName}`, 'Guest', this.forms[guest.formInterest].name, null))));
        location.href = '/print'; // send to print page, force reload
      });
    });
  }

  guestsValid() {
    return this.guests.every(guest => (guest.firstName && guest.lastName) !== '');
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }
}
