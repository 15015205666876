import { Component, OnInit } from '@angular/core';
import { CustomValidators } from 'ng2-validation';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Volunteer } from '../_models/volunteer.model';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-volunteer-sign-up',
  templateUrl: './volunteer-sign-up.component.html',
  styleUrls: ['./volunteer-sign-up.component.css']
})
export class VolunteerSignUpComponent implements OnInit {
  public volunteers: Array<Volunteer> = [];
  public api = environment.apiUrl;
  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  volunteerSignupForm: FormGroup;
  isSubmitted = false;
  email = '';

  availableOptions: Array<String> = [
    '12 AM', '1 AM', '2 AM', '3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM',
    '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM'
  ];

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      }
    )
  };

  constructor(private http: HttpClient, public router: Router) { }

  ngOnInit() {
    this.volunteerSignupForm = new FormGroup({
      firstName__c: new FormControl('', Validators.required),
      lastName__c: new FormControl('', Validators.required),
      email__c: new FormControl('', [Validators.required, CustomValidators.email], this.validateEmailNotTaken.bind(this)),
      phone__c: new FormControl('', Validators.required),
      interests__c: new FormControl(''),
      level__c: new FormControl(''),
      Name: new FormControl(''),
    });

    this.days.forEach(day => {
      ['Start', 'End', ''].forEach(time => this.volunteerSignupForm.addControl(`${day}${time}Time__c`, new FormControl('')));
    });

    this.volunteerSignupForm.patchValue({level__c: 'orientation'});
  }

  validateEmailNotTaken() { // async
    return new Promise(resolve => {
      // check if user exists in sign up
      this.http.get<any>(this.api + 'path/VolunteerSignUpForm__c/Name/' + this.volunteerSignupForm.controls['email__c'].value).subscribe(
        () => resolve({ 'emailInUse': true }), // 200 OK = user exists, return error
        error => {
          resolve(error.status === 404 ? null : { 'emailInUse': true }); // 404 Not Found = user does not exist
        });
    });
  }

  submit() {
    this.isSubmitted = true;
    if (this.volunteerSignupForm.invalid) {
      return;
    }

    this.email = this.volunteerSignupForm.controls['email__c'].value;
    this.volunteerSignupForm.patchValue({Name: this.email});

    this.days.forEach(day => {
      const [start, end] = ['StartTime__c', 'EndTime__c'].map(time => this.volunteerSignupForm.value[day + time]);
      this.volunteerSignupForm.patchValue({[`${day}Time__c`]: `${start} - ${end}`});
    });

    this.http.post(this.api + 'path/VolunteerSignUpForm__c', this.volunteerSignupForm.value, this.httpOptions).subscribe(result => {
      alert('You have successfully signed-up.\nPlease check in now.');
      this.router.navigateByUrl('/home');
    });
  }

  handleEnterKeyPress(event) {
    if (event.target.tagName !== 'TEXTAREA') { return false; }
  }

  dayValid(day: string) {
    const start = this.volunteerSignupForm.value[day + 'StartTime__c'];
    const end = this.volunteerSignupForm.value[day + 'EndTime__c'];
    if ((start || end) !== '') { // if either start or end is set...
      return start !== '' && end !== ''; // both must be set to be valid
    }
    return true; // neither start nor end is set, valid
  }

  daysValid() {
    // valid if...
    return this.days.every(day => this.dayValid(day)) && // every day is valid
           this.days.some(day => (this.volunteerSignupForm.value[day + 'StartTime__c'] && this.volunteerSignupForm.value[day + 'EndTime__c']) !== ''); // and at least one day has a time set
  }
}
