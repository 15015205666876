import { GuestOptionsComponent } from './guest-options/guest-options.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { Routes, RouterModule } from '@angular/router';
import { VolunteerCheckInComponent } from './volunteer-check-in/volunteer-check-in.component';
import { VolunteerOptionsComponent } from './volunteer-options/volunteer-options.component';
import { VolunteerSignUpComponent } from './volunteer-sign-up/volunteer-sign-up.component';
import { GuestSignUpComponent } from './guest-sign-up/guest-sign-up.component';
import { GuestCheckInComponent } from './guest-check-in/guest-check-in.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home'},
  { path: 'guest-options', component: GuestOptionsComponent },
  { path: 'home', component: HomeComponent },
  { path: 'print', component: PrintLayoutComponent },
  { path: 'print-volunteer', component: PrintLayoutComponent },
  { path: 'volunteer-checkin', component: VolunteerCheckInComponent },
  { path: 'volunteer-options', component: VolunteerOptionsComponent },
  { path: 'volunteer-signup', component: VolunteerSignUpComponent },
  { path: 'guest-signup', component: GuestSignUpComponent },
  { path: 'guest-checkin', component: GuestCheckInComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
