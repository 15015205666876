import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CustomValidators } from 'ng2-validation';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Volunteer } from '../_models/volunteer.model';
import { environment } from '../../environments/environment';
import { PrintUser } from '../_models/print-user.model';

@Component({
  selector: 'app-volunteer-check-in',
  templateUrl: './volunteer-check-in.component.html',
  styleUrls: ['./volunteer-check-in.component.css']
})
export class VolunteerCheckInComponent implements OnInit {
  public api = environment.apiUrl;
  volunteerCheckInForm: FormGroup;
  volunteerCheckOutForm: FormGroup;
  isSubmitted = false;
  volunteers: Volunteer[];
  volunteer: Volunteer;
  fullName = '';
  volunteerLevel = '';
  today = '';
  time = '';
  todayDate;
  recordName = '';
  formError = null;

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      }
    )
  };

  constructor(private http: HttpClient, public router: Router, private cookieService: CookieService, private datePipe: DatePipe) {
    this.cookieService.delete('guests');
  }

  ngOnInit() {
    this.today = this.datePipe.transform(Date(), 'M-d-yyyy');
    this.time = this.datePipe.transform(Date(), 'HH:mm:ss');
    this.todayDate = Date.parse(this.today);

    this.volunteerCheckInForm = new FormGroup({
      email__c: new FormControl('', [Validators.required, CustomValidators.email]),
      date__c: new FormControl(''),
      timeIn__c: new FormControl(''),
      Name: new FormControl(''),
    });

    this.volunteerCheckOutForm = new FormGroup({
      timeOut__c: new FormControl(''),
    });
  }

  checkIn() {
    this.formError = null;
    this.isSubmitted = true;
    this.volunteers = [];
    this.volunteerCheckInForm.patchValue({date__c: this.todayDate});
    this.volunteerCheckInForm.patchValue({timeIn__c: this.time});
    this.recordName = this.volunteerCheckInForm.controls['email__c'].value + this.today;
    this.volunteerCheckInForm.patchValue({Name: this.recordName});
    // check if email exists in sign-up
    this.http.get<any>(this.api + 'path/VolunteerSignUpForm__c/Name/' + this.volunteerCheckInForm.controls['email__c'].value, this.httpOptions).subscribe(result => {
      this.volunteer = result;
      this.volunteerLevel = this.volunteer.level__c;
      this.fullName = this.volunteer.firstName__c + ' ' + this.volunteer.lastName__c;
      this.cookieService.set('guests', '[' + new PrintUser(this.fullName, 'Volunteer', null, this.volunteerLevel).json + ']');
        // check time sheet for email, should return 404
        this.http.get<any>(this.api + 'path/VolunteerTimeSheet__c/Name/' + this.volunteerCheckInForm.controls['email__c'].value + this.today, this.httpOptions).subscribe(() => {
          // user is already checked-in if status is 200
          this.isSubmitted = false;
          this.formError = 'You have already checked-in';
        }, error => {
          if (error.status === 404) {
            this.http.post(this.api + 'path/VolunteerTimeSheet__c', this.volunteerCheckInForm.value, this.httpOptions).subscribe(() => {
              location.href = '/print-volunteer';
            });
          } else {
            // status is not 404 or 200, other error occured
            this.isSubmitted = false;
            this.formError = `An unknown error occured: ${error.status} ${error.statusText}`;
          }
        });
    }, error => {
      this.isSubmitted = false;
      this.formError = `An unknown error occured: ${error.status} ${error.statusText}`;
      switch (error.status) {
        case 404: // email does not exists in sign-up
          this.formError = 'That email was not found';
          break;
      }
    });
  }

  checkOut() {
    this.isSubmitted = true;
    this.formError = null;
    this.volunteerCheckOutForm.patchValue({timeOut__c: this.time});
    const fullPath = this.api + 'path/VolunteerTimeSheet__c/Name/' + this.volunteerCheckInForm.controls['email__c'].value + this.today;
    this.http.get<any>(fullPath, this.httpOptions).subscribe(result => {
      if (!result.timeOut__c) { // check time sheet and make sure user has not already checked-out
        this.http.patch(fullPath, this.volunteerCheckOutForm.value, this.httpOptions).subscribe(() => {
          alert('Checked out, thank you');
          this.router.navigateByUrl('/home');
        });
      } else {
        this.isSubmitted = false;
        this.formError = 'You have already checked-out';
      }
    }, error => {
      this.isSubmitted = false;
      this.formError = `An unknown error occured: ${error.status} ${error.statusText}`;
      switch (error.status) {
        case 404:
          this.formError = 'That email was not found';
      }
    });
  }

  get formControls() { return this.volunteerCheckInForm.controls; }
}
