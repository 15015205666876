import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Questions } from './questions.model';

export class Guest {
    public firstName: string;
    public lastName: string;
    private _formInterest: string;
    public formGroup: FormGroup;
    constructor(firstName: string, lastName: string, formInterest: string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this._formInterest = formInterest;
        this.createFormGroup();
    }

    createFormGroup() {
        let group = {};
        for (let i = 1; i <= Questions.forms[this._formInterest].questions.length; ++i) {
            group[`question${i}__c`] = new FormControl('', Validators.required);
        }
        this.formGroup = new FormGroup(group);
    }

    set formInterest(interest: string) {
        this._formInterest = interest;
        this.createFormGroup();
    }

    get formInterest(): string {
        return this._formInterest;
    }
}
