import { Component, OnInit, AfterViewInit, ViewEncapsulation, HostListener } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { PrintUser } from '../_models/print-user.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.css'],
  encapsulation: ViewEncapsulation.None // allow css to target html and body
})

export class PrintLayoutComponent implements OnInit, AfterViewInit {
  guests: Array<PrintUser> = [];
  index = 0;
  printTimeout = 0;

  constructor(public router: Router, private cookieService: CookieService, private deviceService: DeviceDetectorService) {
    this.guests = JSON.parse(this.cookieService.get('guests'));
    const deviceInfo = this.deviceService.getDeviceInfo();
    Object.keys(deviceInfo).forEach(key => {
      const valueSlug = deviceInfo[key].replace(/\s+/g, '-').replace(/[^a-z0-9]/ig, '').toLowerCase();
      document.body.classList.add(`${key.replace(/_/g, '-')}-${valueSlug}`);
    });
  }

  ngOnInit() {
    setTimeout(this.home, 120e3); // don't stay on print page forever
  }

  ngAfterViewInit() {
    if (this.guests[0].role === 'Guest') {
      this.print();
    }
  }

  print(delay = 1.5e3) {
    clearTimeout(this.printTimeout);
    this.printTimeout = window.setTimeout(() => {
      window.print();
      this.guests[this.index].printed = true;
    }, delay); // give user time to read dialog
  }

  next() {
    ++this.index;
    this.print(100);
  }

  previous() {
    --this.index;
    this.print(100);
  }

  @HostListener('window:popstate') // catch back button and send back to home
  home() {
    if (this.guests.some(guest => !guest.printed)) { // if any guest isn't printed, ask for confirmation to leave
      const leave = window.confirm('You haven\'t printed all your nametags yet.\nDo you want to leave?');
      if (!leave) { return; }
    }
    location.href = '/'; // force reload
  }
}
