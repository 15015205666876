import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  guest() {
    this.router.navigateByUrl('/guest-options');
  }

  volunteer() {
    this.router.navigateByUrl('/volunteer-options');
  }

}
