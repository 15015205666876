import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Questions } from '../_models/questions.model';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrintUser } from '../_models/print-user.model';

@Component({
  selector: 'app-guest-check-in',
  templateUrl: './guest-check-in.component.html',
  styleUrls: ['./guest-check-in.component.css']
})
export class GuestCheckInComponent implements OnInit {
  public api = environment.apiUrl;
  guestCheckInForm: FormGroup;
  formError = null;
  isSubmitted = false;
  guests = null;
  forms = Questions.forms;

  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
      }
    )
  };

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) { }

  ngOnInit() {
    this.guestCheckInForm = new FormGroup({ 'email__c': new FormControl('', Validators.required) });
  }

  guestsInvalid() {
    return this.guests.every(guest => !guest.enabled); // invalid if all unchecked
  }

  checkIn() {
    this.isSubmitted = true;
    this.http.get<any>(this.api + 'path/GuestSignUpForm__c/email__c/' + this.guestCheckInForm.value.email__c, this.httpOptions).subscribe(result => {
      const requests = Object.keys(this.forms).map(form => // get list of form endpoints, map returns array of each http observable
        this.http.get<any>(`${this.api}path/GuestSignUpForm__c/${result.Id}/${form.slice(0, -3)}s__r`)
        // get form endpoint at GuestSignUpForm__c/${guest id}/${plural form name}__r    ^ remove __c from end of form and replace with __r, pluralizing formname
      );
      forkJoin(requests).subscribe(responses => {
        this.guests = responses.flatMap(form => form.records); // map form response to form records, flattening afterwards
        this.guests.forEach(guest => { // enable each guest
          guest['enabled'] = true;
        });
        this.isSubmitted = false;
        if (this.guests.length === 1) { // skip guest selection if only 1 registered
          this.checkInGuests();
        }
      });
    }, error => {
      this.isSubmitted = false;
      this.formError = `An unknown error occured: ${error.status} ${error.statusText}`;
      switch (error.status) {
        case 404: // email does not exists in sign-up
          this.formError = 'That email was not found';
          break;
      }
    });
  }

  checkInGuests() {
    this.cookieService.set('guests', JSON.stringify( // set cookie for print
      this.guests.filter(guest => guest.enabled).map(guest => // create print object for each enabled guest
        new PrintUser(`${guest.firstName__c} ${guest.lastName__c}`, 'Guest', this.forms[guest.attributes.type].name, null))
    ));
    location.href = '/print'; // send to print page, force reload
  }
}
