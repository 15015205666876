import { NumericQuestion } from './numeric-question.model';

export class Questions {
  public static forms = {
    'EquineAssistedForm__c': {
      'id': 'EquineAssistedForm__c', 'name': 'Equine Assisted Learning', 'questions': [
        new NumericQuestion('question1__c', 'Question 1', 'To what extent do you feel you are willing to try new things and take risks?'),
        new NumericQuestion('question2__c', 'Question 2', 'To what extent do you feel you are able to stay on task and engaged in an extended activity?'),
        new NumericQuestion('question3__c', 'Question 3', 'To what extent do you feel you are honest, forthcoming, and able to take responsibility for your actions?'),
        new NumericQuestion('question4__c', 'Question 4', 'To what extent do you feel you are respectful toward the people around you?'),
        new NumericQuestion('question5__c', 'Question 5', 'To what extent do you feel you contribute personal energy and motivate/lead others?'),
        new NumericQuestion('question6__c', 'Question 6', 'To what extent do you feel you address your anxiety or fear appropriately?'),
        new NumericQuestion('question7__c', 'Question 7', 'To what extent do you feel you engage positively with others?'),
        new NumericQuestion('question8__c', 'Question 8', 'To what extent do you lead discussions and listen to others during discussions?'),
        new NumericQuestion('question9__c', 'Question 9', 'To what extent can you self-regulate your behavior?'),
        new NumericQuestion('question10__c', 'Question 10', 'To what extent do you demonstrate patience and persistence?'),
        new NumericQuestion('question11__c', 'Question 11', 'To what extent do you follow rules?'),
        new NumericQuestion('question12__c', 'Question 12', 'To what extent do you take initiative to understand directions and follow directions willingly?'),
        new NumericQuestion('question13__c', 'Question 13', 'To what extent are you sensitive to your own boundaries and the boundaries of others?'),
      ]
    },
    'MilitaryHorsemanshipForm__c': {
      'id': 'MilitaryHorsemanshipForm__c', 'name': 'Military Horsemanship', 'questions': [
        new NumericQuestion('question1__c', 'Question 1', 'To what extent do you trust horses?'),
        new NumericQuestion('question2__c', 'Question 2', 'To what extent can you safely and confidently handle a horse on the ground?'),
        new NumericQuestion('question3__c', 'Question 3', 'To what extent can you feed, groom, and care for the health of a horse?'),
        new NumericQuestion('question4__c', 'Question 4', 'To what extent can you confidently ride a horse (stop, go, turn, balance, walk, trot canter)?'),
        new NumericQuestion('question5__c', 'Question 5', 'To what extent can you ride continuously for 1.5 hours?'),
        new NumericQuestion('question6__c', 'Question 6', 'To what extent are you comfortable describing your physical and emotional condition out loud, in a small group?'),
        new NumericQuestion('question7__c', 'Question 7', 'How confident and persistent are you when you’re engaged in new experiences?'),
        new NumericQuestion('question8__c', 'Question 8', 'How comfortable are you with taking risks?'),
        new NumericQuestion('question9__c', 'Question 9', 'To what extent are you comfortable and able to critically reflect on your biases, stereotypes, assumptions and fears?'),
        new NumericQuestion('question10__c', 'Question 10', 'To what extent are you comfortable trying out new ways of thinking and acting?'),
      ]
    },
    'AdaptiveRidingForm__c': {
      'id': 'AdaptiveRidingForm__c', 'name': 'Adaptive Riding', 'questions': [
        new NumericQuestion('question1__c', 'Question 1', 'To what extent do you trust horses?'),
        new NumericQuestion('question2__c', 'Question 2', 'To what extent can you safely and confidently handle a horse on the ground?'),
        new NumericQuestion('question3__c', 'Question 3', 'To what extent can you feed, groom, and care for the health of a horse?'),
        new NumericQuestion('question4__c', 'Question 4', 'To what extent can you confidently ride a horse (stop, go, turn, balance, walk, trot canter)?'),
        new NumericQuestion('question5__c', 'Question 5', 'How would you rate your physical balance?'),
        new NumericQuestion('question6__c', 'Question 6', 'How would you rate your breathing comfort?'),
        new NumericQuestion('question7__c', 'Question 7', 'How would you rate your physical coordination?'),
        new NumericQuestion('question8__c', 'Question 8', 'How would you rate your physical flexibility?'),
        new NumericQuestion('question9__c', 'Question 9', 'How would you rate your muscle strength?'),
        new NumericQuestion('question10__c', 'Question 10', 'How would you rate your ability to follow multi-step directions?'),
        new NumericQuestion('question11__c', 'Question 11', 'How would you rate your current level of self-confidence?'),
        new NumericQuestion('question12__c', 'Question 12', 'How would you rate your ability to engage in social interactions and relationships?'),
        new NumericQuestion('question13__c', 'Question 13', 'How would you rate your self-esteem, or how you feel about yourself?'),
      ]
    }
  };
}
