export class NumericQuestion {
    public formName: string;
    public displayName: string;
    public question: string;
    /**
     * Creates a new question
     * 
     * @param formName name used in forms and API name in salesforce
     * @param displayName human readable name, not used
     * @param question question content
     */
    constructor (formName: string, displayName: string, question: string) {
        this.formName = formName;
        this.displayName = displayName;
        this.question = question;
    }
}
