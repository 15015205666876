import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe, formatDate } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GuestCheckInComponent } from './guest-check-in/guest-check-in.component';
import { GuestOptionsComponent } from './guest-options/guest-options.component';
import { GuestSignUpComponent } from './guest-sign-up/guest-sign-up.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { IMaskModule } from 'angular-imask';
import { MatButtonModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatStepperModule, MatIconModule, MatCheckboxModule } from '@angular/material';
import { NgModule } from '@angular/core';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { VolunteerCheckInComponent } from './volunteer-check-in/volunteer-check-in.component';
import { VolunteerOptionsComponent } from './volunteer-options/volunteer-options.component';
import { VolunteerSignUpComponent } from './volunteer-sign-up/volunteer-sign-up.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    GuestOptionsComponent,
    HomeComponent,
    PrintLayoutComponent,
    VolunteerCheckInComponent,
    VolunteerOptionsComponent,
    VolunteerSignUpComponent,
    GuestSignUpComponent,
    GuestCheckInComponent,
  ],
  imports: [
    AngularSvgIconModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    DeviceDetectorModule.forRoot(),
    FormsModule,
    HttpClientModule,
    IMaskModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatStepperModule,
    ReactiveFormsModule,
  ],
  providers: [CookieService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
